/* as seen at https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.xs-p-0 {
    padding: 0px;
}

.xs-p-5 {
    padding: 5px;
}

.xs-p-10 {
    padding: 10px;
}

.xs-p-15 {
    padding: 15px;
}

.xs-p-20 {
    padding: 20px;
}

.xs-p-25 {
    padding: 25px;
}

.xs-p-30 {
    padding: 30px;
}

.xs-p-35 {
    padding: 35px;
}

.xs-p-40 {
    padding: 40px;
}

.xs-p-45 {
    padding: 45px;
}

.xs-p-50 {
    padding: 50px;
}

.xs-p-55 {
    padding: 55px;
}

.xs-p-60 {
    padding: 60px;
}

.xs-p-65 {
    padding: 65px;
}

.xs-p-70 {
    padding: 70px;
}

.xs-p-75 {
    padding: 75px;
}

.xs-p-95 {
    padding: 95px;
}

.xs-p-100 {
    padding: 100px;
}

.xs-pt-0 {
    padding-top: 0px;
}

.xs-pt-5 {
    padding-top: 5px;
}

.xs-pt-10 {
    padding-top: 10px;
}

.xs-pt-15 {
    padding-top: 15px;
}

.xs-pt-20 {
    padding-top: 20px;
}

.xs-pt-25 {
    padding-top: 25px;
}

.xs-pt-30 {
    padding-top: 30px;
}

.xs-pt-35 {
    padding-top: 35px;
}

.xs-pt-40 {
    padding-top: 40px;
}

.xs-pt-45 {
    padding-top: 45px;
}

.xs-pt-50 {
    padding-top: 50px;
}

.xs-pt-55 {
    padding-top: 55px;
}

.xs-pt-60 {
    padding-top: 60px;
}

.xs-pt-65 {
    padding-top: 65px;
}

.xs-pt-70 {
    padding-top: 70px;
}

.xs-pt-75 {
    padding-top: 75px;
}

.xs-pt-95 {
    padding-top: 95px;
}

.xs-pt-100 {
    padding-top: 100px;
}

.xs-pr-0 {
    padding-right: 0px;
}

.xs-pr-5 {
    padding-right: 5px;
}

.xs-pr-10 {
    padding-right: 10px;
}

.xs-pr-15 {
    padding-right: 15px;
}

.xs-pr-20 {
    padding-right: 20px;
}

.xs-pr-25 {
    padding-right: 25px;
}

.xs-pr-30 {
    padding-right: 30px;
}

.xs-pr-35 {
    padding-right: 35px;
}

.xs-pr-40 {
    padding-right: 40px;
}

.xs-pr-45 {
    padding-right: 45px;
}

.xs-pr-50 {
    padding-right: 50px;
}

.xs-pr-55 {
    padding-right: 55px;
}

.xs-pr-60 {
    padding-right: 60px;
}

.xs-pr-65 {
    padding-right: 65px;
}

.xs-pr-70 {
    padding-right: 70px;
}

.xs-pr-75 {
    padding-right: 75px;
}

.xs-pr-95 {
    padding-right: 95px;
}

.xs-pr-100 {
    padding-right: 100px;
}

.xs-pb-0 {
    padding-bottom: 0px;
}

.xs-pb-5 {
    padding-bottom: 5px;
}

.xs-pb-10 {
    padding-bottom: 10px;
}

.xs-pb-15 {
    padding-bottom: 15px;
}

.xs-pb-20 {
    padding-bottom: 20px;
}

.xs-pb-25 {
    padding-bottom: 25px;
}

.xs-pb-30 {
    padding-bottom: 30px;
}

.xs-pb-35 {
    padding-bottom: 35px;
}

.xs-pb-40 {
    padding-bottom: 40px;
}

.xs-pb-45 {
    padding-bottom: 45px;
}

.xs-pb-50 {
    padding-bottom: 50px;
}

.xs-pb-55 {
    padding-bottom: 55px;
}

.xs-pb-60 {
    padding-bottom: 60px;
}

.xs-pb-65 {
    padding-bottom: 65px;
}

.xs-pb-70 {
    padding-bottom: 70px;
}

.xs-pb-75 {
    padding-bottom: 75px;
}

.xs-pb-95 {
    padding-bottom: 95px;
}

.xs-pb-100 {
    padding-bottom: 100px;
}

.xs-pl-0 {
    padding-left: 0px;
}

.xs-pl-5 {
    padding-left: 5px;
}

.xs-pl-10 {
    padding-left: 10px;
}

.xs-pl-15 {
    padding-left: 15px;
}

.xs-pl-20 {
    padding-left: 20px;
}

.xs-pl-25 {
    padding-left: 25px;
}

.xs-pl-30 {
    padding-left: 30px;
}

.xs-pl-35 {
    padding-left: 35px;
}

.xs-pl-40 {
    padding-left: 40px;
}

.xs-pl-45 {
    padding-left: 45px;
}

.xs-pl-50 {
    padding-left: 50px;
}

.xs-pl-55 {
    padding-left: 55px;
}

.xs-pl-60 {
    padding-left: 60px;
}

.xs-pl-65 {
    padding-left: 65px;
}

.xs-pl-70 {
    padding-left: 70px;
}

.xs-pl-75 {
    padding-left: 75px;
}

.xs-pl-95 {
    padding-left: 95px;
}

.xs-pl-100 {
    padding-left: 100px;
}

.xs-m-0 {
    margin: 0px;
}

.xs-m-5 {
    margin: 5px;
}

.xs-m-10 {
    margin: 10px;
}

.xs-m-15 {
    margin: 15px;
}

.xs-m-20 {
    margin: 20px;
}

.xs-m-25 {
    margin: 25px;
}

.xs-m-30 {
    margin: 30px;
}

.xs-m-35 {
    margin: 35px;
}

.xs-m-40 {
    margin: 40px;
}

.xs-m-45 {
    margin: 45px;
}

.xs-m-50 {
    margin: 50px;
}

.xs-m-55 {
    margin: 55px;
}

.xs-m-60 {
    margin: 60px;
}

.xs-m-65 {
    margin: 65px;
}

.xs-m-70 {
    margin: 70px;
}

.xs-m-75 {
    margin: 75px;
}

.xs-m-95 {
    margin: 95px;
}

.xs-m-100 {
    margin: 100px;
}

.xs-mt-0 {
    margin-top: 0px;
}

.xs-mt-5 {
    margin-top: 5px;
}

.xs-mt-10 {
    margin-top: 10px;
}

.xs-mt-15 {
    margin-top: 15px;
}

.xs-mt-20 {
    margin-top: 20px;
}

.xs-mt-25 {
    margin-top: 25px;
}

.xs-mt-30 {
    margin-top: 30px;
}

.xs-mt-35 {
    margin-top: 35px;
}

.xs-mt-40 {
    margin-top: 40px;
}

.xs-mt-45 {
    margin-top: 45px;
}

.xs-mt-50 {
    margin-top: 50px;
}

.xs-mt-55 {
    margin-top: 55px;
}

.xs-mt-60 {
    margin-top: 60px;
}

.xs-mt-65 {
    margin-top: 65px;
}

.xs-mt-70 {
    margin-top: 70px;
}

.xs-mt-75 {
    margin-top: 75px;
}

.xs-mt-95 {
    margin-top: 95px;
}

.xs-mt-100 {
    margin-top: 100px;
}

.xs-mr-0 {
    margin-right: 0px;
}

.xs-mr-5 {
    margin-right: 5px;
}

.xs-mr-10 {
    margin-right: 10px;
}

.xs-mr-15 {
    margin-right: 15px;
}

.xs-mr-20 {
    margin-right: 20px;
}

.xs-mr-25 {
    margin-right: 25px;
}

.xs-mr-30 {
    margin-right: 30px;
}

.xs-mr-35 {
    margin-right: 35px;
}

.xs-mr-40 {
    margin-right: 40px;
}

.xs-mr-45 {
    margin-right: 45px;
}

.xs-mr-50 {
    margin-right: 50px;
}

.xs-mr-55 {
    margin-right: 55px;
}

.xs-mr-60 {
    margin-right: 60px;
}

.xs-mr-65 {
    margin-right: 65px;
}

.xs-mr-70 {
    margin-right: 70px;
}

.xs-mr-75 {
    margin-right: 75px;
}

.xs-mr-95 {
    margin-right: 95px;
}

.xs-mr-100 {
    margin-right: 100px;
}

.xs-mb-0 {
    margin-bottom: 0px;
}

.xs-mb-5 {
    margin-bottom: 5px;
}

.xs-mb-10 {
    margin-bottom: 10px;
}

.xs-mb-15 {
    margin-bottom: 15px;
}

.xs-mb-20 {
    margin-bottom: 20px;
}

.xs-mb-25 {
    margin-bottom: 25px;
}

.xs-mb-30 {
    margin-bottom: 30px;
}

.xs-mb-35 {
    margin-bottom: 35px;
}

.xs-mb-40 {
    margin-bottom: 40px;
}

.xs-mb-45 {
    margin-bottom: 45px;
}

.xs-mb-50 {
    margin-bottom: 50px;
}

.xs-mb-55 {
    margin-bottom: 55px;
}

.xs-mb-60 {
    margin-bottom: 60px;
}

.xs-mb-65 {
    margin-bottom: 65px;
}

.xs-mb-70 {
    margin-bottom: 70px;
}

.xs-mb-75 {
    margin-bottom: 75px;
}

.xs-mb-95 {
    margin-bottom: 95px;
}

.xs-mb-100 {
    margin-bottom: 100px;
}

.xs-ml-0 {
    margin-left: 0px;
}

.xs-ml-5 {
    margin-left: 5px;
}

.xs-ml-10 {
    margin-left: 10px;
}

.xs-ml-15 {
    margin-left: 15px;
}

.xs-ml-20 {
    margin-left: 20px;
}

.xs-ml-25 {
    margin-left: 25px;
}

.xs-ml-30 {
    margin-left: 30px;
}

.xs-ml-35 {
    margin-left: 35px;
}

.xs-ml-40 {
    margin-left: 40px;
}

.xs-ml-45 {
    margin-left: 45px;
}

.xs-ml-50 {
    margin-left: 50px;
}

.xs-ml-55 {
    margin-left: 55px;
}

.xs-ml-60 {
    margin-left: 60px;
}

.xs-ml-65 {
    margin-left: 65px;
}

.xs-ml-70 {
    margin-left: 70px;
}

.xs-ml-75 {
    margin-left: 75px;
}

.xs-ml-95 {
    margin-left: 95px;
}

.xs-ml-100 {
    margin-left: 100px;
}

@media screen and (min-width: 768px) {
    .sm-p-0 {
        padding: 0px;
    }
    .sm-p-5 {
        padding: 5px;
    }
    .sm-p-10 {
        padding: 10px;
    }
    .sm-p-15 {
        padding: 15px;
    }
    .sm-p-20 {
        padding: 20px;
    }
    .sm-p-25 {
        padding: 25px;
    }
    .sm-p-30 {
        padding: 30px;
    }
    .sm-p-35 {
        padding: 35px;
    }
    .sm-p-40 {
        padding: 40px;
    }
    .sm-p-45 {
        padding: 45px;
    }
    .sm-p-50 {
        padding: 50px;
    }
    .sm-p-55 {
        padding: 55px;
    }
    .sm-p-60 {
        padding: 60px;
    }
    .sm-p-65 {
        padding: 65px;
    }
    .sm-p-70 {
        padding: 70px;
    }
    .sm-p-75 {
        padding: 75px;
    }
    .sm-p-95 {
        padding: 95px;
    }
    .sm-p-100 {
        padding: 100px;
    }
    .sm-pt-0 {
        padding-top: 0px;
    }
    .sm-pt-5 {
        padding-top: 5px;
    }
    .sm-pt-10 {
        padding-top: 10px;
    }
    .sm-pt-15 {
        padding-top: 15px;
    }
    .sm-pt-20 {
        padding-top: 20px;
    }
    .sm-pt-25 {
        padding-top: 25px;
    }
    .sm-pt-30 {
        padding-top: 30px;
    }
    .sm-pt-35 {
        padding-top: 35px;
    }
    .sm-pt-40 {
        padding-top: 40px;
    }
    .sm-pt-45 {
        padding-top: 45px;
    }
    .sm-pt-50 {
        padding-top: 50px;
    }
    .sm-pt-55 {
        padding-top: 55px;
    }
    .sm-pt-60 {
        padding-top: 60px;
    }
    .sm-pt-65 {
        padding-top: 65px;
    }
    .sm-pt-70 {
        padding-top: 70px;
    }
    .sm-pt-75 {
        padding-top: 75px;
    }
    .sm-pt-95 {
        padding-top: 95px;
    }
    .sm-pt-100 {
        padding-top: 100px;
    }
    .sm-pr-0 {
        padding-right: 0px;
    }
    .sm-pr-5 {
        padding-right: 5px;
    }
    .sm-pr-10 {
        padding-right: 10px;
    }
    .sm-pr-15 {
        padding-right: 15px;
    }
    .sm-pr-20 {
        padding-right: 20px;
    }
    .sm-pr-25 {
        padding-right: 25px;
    }
    .sm-pr-30 {
        padding-right: 30px;
    }
    .sm-pr-35 {
        padding-right: 35px;
    }
    .sm-pr-40 {
        padding-right: 40px;
    }
    .sm-pr-45 {
        padding-right: 45px;
    }
    .sm-pr-50 {
        padding-right: 50px;
    }
    .sm-pr-55 {
        padding-right: 55px;
    }
    .sm-pr-60 {
        padding-right: 60px;
    }
    .sm-pr-65 {
        padding-right: 65px;
    }
    .sm-pr-70 {
        padding-right: 70px;
    }
    .sm-pr-75 {
        padding-right: 75px;
    }
    .sm-pr-95 {
        padding-right: 95px;
    }
    .sm-pr-100 {
        padding-right: 100px;
    }
    .sm-pb-0 {
        padding-bottom: 0px;
    }
    .sm-pb-5 {
        padding-bottom: 5px;
    }
    .sm-pb-10 {
        padding-bottom: 10px;
    }
    .sm-pb-15 {
        padding-bottom: 15px;
    }
    .sm-pb-20 {
        padding-bottom: 20px;
    }
    .sm-pb-25 {
        padding-bottom: 25px;
    }
    .sm-pb-30 {
        padding-bottom: 30px;
    }
    .sm-pb-35 {
        padding-bottom: 35px;
    }
    .sm-pb-40 {
        padding-bottom: 40px;
    }
    .sm-pb-45 {
        padding-bottom: 45px;
    }
    .sm-pb-50 {
        padding-bottom: 50px;
    }
    .sm-pb-55 {
        padding-bottom: 55px;
    }
    .sm-pb-60 {
        padding-bottom: 60px;
    }
    .sm-pb-65 {
        padding-bottom: 65px;
    }
    .sm-pb-70 {
        padding-bottom: 70px;
    }
    .sm-pb-75 {
        padding-bottom: 75px;
    }
    .sm-pb-95 {
        padding-bottom: 95px;
    }
    .sm-pb-100 {
        padding-bottom: 100px;
    }
    .sm-pl-0 {
        padding-left: 0px;
    }
    .sm-pl-5 {
        padding-left: 5px;
    }
    .sm-pl-10 {
        padding-left: 10px;
    }
    .sm-pl-15 {
        padding-left: 15px;
    }
    .sm-pl-20 {
        padding-left: 20px;
    }
    .sm-pl-25 {
        padding-left: 25px;
    }
    .sm-pl-30 {
        padding-left: 30px;
    }
    .sm-pl-35 {
        padding-left: 35px;
    }
    .sm-pl-40 {
        padding-left: 40px;
    }
    .sm-pl-45 {
        padding-left: 45px;
    }
    .sm-pl-50 {
        padding-left: 50px;
    }
    .sm-pl-55 {
        padding-left: 55px;
    }
    .sm-pl-60 {
        padding-left: 60px;
    }
    .sm-pl-65 {
        padding-left: 65px;
    }
    .sm-pl-70 {
        padding-left: 70px;
    }
    .sm-pl-75 {
        padding-left: 75px;
    }
    .sm-pl-95 {
        padding-left: 95px;
    }
    .sm-pl-100 {
        padding-left: 100px;
    }
    .sm-m-0 {
        margin: 0px;
    }
    .sm-m-5 {
        margin: 5px;
    }
    .sm-m-10 {
        margin: 10px;
    }
    .sm-m-15 {
        margin: 15px;
    }
    .sm-m-20 {
        margin: 20px;
    }
    .sm-m-25 {
        margin: 25px;
    }
    .sm-m-30 {
        margin: 30px;
    }
    .sm-m-35 {
        margin: 35px;
    }
    .sm-m-40 {
        margin: 40px;
    }
    .sm-m-45 {
        margin: 45px;
    }
    .sm-m-50 {
        margin: 50px;
    }
    .sm-m-55 {
        margin: 55px;
    }
    .sm-m-60 {
        margin: 60px;
    }
    .sm-m-65 {
        margin: 65px;
    }
    .sm-m-70 {
        margin: 70px;
    }
    .sm-m-75 {
        margin: 75px;
    }
    .sm-m-95 {
        margin: 95px;
    }
    .sm-m-100 {
        margin: 100px;
    }
    .sm-mt-0 {
        margin-top: 0px;
    }
    .sm-mt-5 {
        margin-top: 5px;
    }
    .sm-mt-10 {
        margin-top: 10px;
    }
    .sm-mt-15 {
        margin-top: 15px;
    }
    .sm-mt-20 {
        margin-top: 20px;
    }
    .sm-mt-25 {
        margin-top: 25px;
    }
    .sm-mt-30 {
        margin-top: 30px;
    }
    .sm-mt-35 {
        margin-top: 35px;
    }
    .sm-mt-40 {
        margin-top: 40px;
    }
    .sm-mt-45 {
        margin-top: 45px;
    }
    .sm-mt-50 {
        margin-top: 50px;
    }
    .sm-mt-55 {
        margin-top: 55px;
    }
    .sm-mt-60 {
        margin-top: 60px;
    }
    .sm-mt-65 {
        margin-top: 65px;
    }
    .sm-mt-70 {
        margin-top: 70px;
    }
    .sm-mt-75 {
        margin-top: 75px;
    }
    .sm-mt-95 {
        margin-top: 95px;
    }
    .sm-mt-100 {
        margin-top: 100px;
    }
    .sm-mr-0 {
        margin-right: 0px;
    }
    .sm-mr-5 {
        margin-right: 5px;
    }
    .sm-mr-10 {
        margin-right: 10px;
    }
    .sm-mr-15 {
        margin-right: 15px;
    }
    .sm-mr-20 {
        margin-right: 20px;
    }
    .sm-mr-25 {
        margin-right: 25px;
    }
    .sm-mr-30 {
        margin-right: 30px;
    }
    .sm-mr-35 {
        margin-right: 35px;
    }
    .sm-mr-40 {
        margin-right: 40px;
    }
    .sm-mr-45 {
        margin-right: 45px;
    }
    .sm-mr-50 {
        margin-right: 50px;
    }
    .sm-mr-55 {
        margin-right: 55px;
    }
    .sm-mr-60 {
        margin-right: 60px;
    }
    .sm-mr-65 {
        margin-right: 65px;
    }
    .sm-mr-70 {
        margin-right: 70px;
    }
    .sm-mr-75 {
        margin-right: 75px;
    }
    .sm-mr-95 {
        margin-right: 95px;
    }
    .sm-mr-100 {
        margin-right: 100px;
    }
    .sm-mb-0 {
        margin-bottom: 0px;
    }
    .sm-mb-5 {
        margin-bottom: 5px;
    }
    .sm-mb-10 {
        margin-bottom: 10px;
    }
    .sm-mb-15 {
        margin-bottom: 15px;
    }
    .sm-mb-20 {
        margin-bottom: 20px;
    }
    .sm-mb-25 {
        margin-bottom: 25px;
    }
    .sm-mb-30 {
        margin-bottom: 30px;
    }
    .sm-mb-35 {
        margin-bottom: 35px;
    }
    .sm-mb-40 {
        margin-bottom: 40px;
    }
    .sm-mb-45 {
        margin-bottom: 45px;
    }
    .sm-mb-50 {
        margin-bottom: 50px;
    }
    .sm-mb-55 {
        margin-bottom: 55px;
    }
    .sm-mb-60 {
        margin-bottom: 60px;
    }
    .sm-mb-65 {
        margin-bottom: 65px;
    }
    .sm-mb-70 {
        margin-bottom: 70px;
    }
    .sm-mb-75 {
        margin-bottom: 75px;
    }
    .sm-mb-95 {
        margin-bottom: 95px;
    }
    .sm-mb-100 {
        margin-bottom: 100px;
    }
    .sm-ml-0 {
        margin-left: 0px;
    }
    .sm-ml-5 {
        margin-left: 5px;
    }
    .sm-ml-10 {
        margin-left: 10px;
    }
    .sm-ml-15 {
        margin-left: 15px;
    }
    .sm-ml-20 {
        margin-left: 20px;
    }
    .sm-ml-25 {
        margin-left: 25px;
    }
    .sm-ml-30 {
        margin-left: 30px;
    }
    .sm-ml-35 {
        margin-left: 35px;
    }
    .sm-ml-40 {
        margin-left: 40px;
    }
    .sm-ml-45 {
        margin-left: 45px;
    }
    .sm-ml-50 {
        margin-left: 50px;
    }
    .sm-ml-55 {
        margin-left: 55px;
    }
    .sm-ml-60 {
        margin-left: 60px;
    }
    .sm-ml-65 {
        margin-left: 65px;
    }
    .sm-ml-70 {
        margin-left: 70px;
    }
    .sm-ml-75 {
        margin-left: 75px;
    }
    .sm-ml-95 {
        margin-left: 95px;
    }
    .sm-ml-100 {
        margin-left: 100px;
    }
}

@media screen and (min-width: 992px) {
    .md-p-0 {
        padding: 0px;
    }
    .md-p-5 {
        padding: 5px;
    }
    .md-p-10 {
        padding: 10px;
    }
    .md-p-15 {
        padding: 15px;
    }
    .md-p-20 {
        padding: 20px;
    }
    .md-p-25 {
        padding: 25px;
    }
    .md-p-30 {
        padding: 30px;
    }
    .md-p-35 {
        padding: 35px;
    }
    .md-p-40 {
        padding: 40px;
    }
    .md-p-45 {
        padding: 45px;
    }
    .md-p-50 {
        padding: 50px;
    }
    .md-p-55 {
        padding: 55px;
    }
    .md-p-60 {
        padding: 60px;
    }
    .md-p-65 {
        padding: 65px;
    }
    .md-p-70 {
        padding: 70px;
    }
    .md-p-75 {
        padding: 75px;
    }
    .md-p-95 {
        padding: 95px;
    }
    .md-p-100 {
        padding: 100px;
    }
    .md-pt-0 {
        padding-top: 0px;
    }
    .md-pt-5 {
        padding-top: 5px;
    }
    .md-pt-10 {
        padding-top: 10px;
    }
    .md-pt-15 {
        padding-top: 15px;
    }
    .md-pt-20 {
        padding-top: 20px;
    }
    .md-pt-25 {
        padding-top: 25px;
    }
    .md-pt-30 {
        padding-top: 30px;
    }
    .md-pt-35 {
        padding-top: 35px;
    }
    .md-pt-40 {
        padding-top: 40px;
    }
    .md-pt-45 {
        padding-top: 45px;
    }
    .md-pt-50 {
        padding-top: 50px;
    }
    .md-pt-55 {
        padding-top: 55px;
    }
    .md-pt-60 {
        padding-top: 60px;
    }
    .md-pt-65 {
        padding-top: 65px;
    }
    .md-pt-70 {
        padding-top: 70px;
    }
    .md-pt-75 {
        padding-top: 75px;
    }
    .md-pt-95 {
        padding-top: 95px;
    }
    .md-pt-100 {
        padding-top: 100px;
    }
    .md-pr-0 {
        padding-right: 0px;
    }
    .md-pr-5 {
        padding-right: 5px;
    }
    .md-pr-10 {
        padding-right: 10px;
    }
    .md-pr-15 {
        padding-right: 15px;
    }
    .md-pr-20 {
        padding-right: 20px;
    }
    .md-pr-25 {
        padding-right: 25px;
    }
    .md-pr-30 {
        padding-right: 30px;
    }
    .md-pr-35 {
        padding-right: 35px;
    }
    .md-pr-40 {
        padding-right: 40px;
    }
    .md-pr-45 {
        padding-right: 45px;
    }
    .md-pr-50 {
        padding-right: 50px;
    }
    .md-pr-55 {
        padding-right: 55px;
    }
    .md-pr-60 {
        padding-right: 60px;
    }
    .md-pr-65 {
        padding-right: 65px;
    }
    .md-pr-70 {
        padding-right: 70px;
    }
    .md-pr-75 {
        padding-right: 75px;
    }
    .md-pr-95 {
        padding-right: 95px;
    }
    .md-pr-100 {
        padding-right: 100px;
    }
    .md-pb-0 {
        padding-bottom: 0px;
    }
    .md-pb-5 {
        padding-bottom: 5px;
    }
    .md-pb-10 {
        padding-bottom: 10px;
    }
    .md-pb-15 {
        padding-bottom: 15px;
    }
    .md-pb-20 {
        padding-bottom: 20px;
    }
    .md-pb-25 {
        padding-bottom: 25px;
    }
    .md-pb-30 {
        padding-bottom: 30px;
    }
    .md-pb-35 {
        padding-bottom: 35px;
    }
    .md-pb-40 {
        padding-bottom: 40px;
    }
    .md-pb-45 {
        padding-bottom: 45px;
    }
    .md-pb-50 {
        padding-bottom: 50px;
    }
    .md-pb-55 {
        padding-bottom: 55px;
    }
    .md-pb-60 {
        padding-bottom: 60px;
    }
    .md-pb-65 {
        padding-bottom: 65px;
    }
    .md-pb-70 {
        padding-bottom: 70px;
    }
    .md-pb-75 {
        padding-bottom: 75px;
    }
    .md-pb-95 {
        padding-bottom: 95px;
    }
    .md-pb-100 {
        padding-bottom: 100px;
    }
    .md-pl-0 {
        padding-left: 0px;
    }
    .md-pl-5 {
        padding-left: 5px;
    }
    .md-pl-10 {
        padding-left: 10px;
    }
    .md-pl-15 {
        padding-left: 15px;
    }
    .md-pl-20 {
        padding-left: 20px;
    }
    .md-pl-25 {
        padding-left: 25px;
    }
    .md-pl-30 {
        padding-left: 30px;
    }
    .md-pl-35 {
        padding-left: 35px;
    }
    .md-pl-40 {
        padding-left: 40px;
    }
    .md-pl-45 {
        padding-left: 45px;
    }
    .md-pl-50 {
        padding-left: 50px;
    }
    .md-pl-55 {
        padding-left: 55px;
    }
    .md-pl-60 {
        padding-left: 60px;
    }
    .md-pl-65 {
        padding-left: 65px;
    }
    .md-pl-70 {
        padding-left: 70px;
    }
    .md-pl-75 {
        padding-left: 75px;
    }
    .md-pl-95 {
        padding-left: 95px;
    }
    .md-pl-100 {
        padding-left: 100px;
    }
    .md-m-0 {
        margin: 0px;
    }
    .md-m-5 {
        margin: 5px;
    }
    .md-m-10 {
        margin: 10px;
    }
    .md-m-15 {
        margin: 15px;
    }
    .md-m-20 {
        margin: 20px;
    }
    .md-m-25 {
        margin: 25px;
    }
    .md-m-30 {
        margin: 30px;
    }
    .md-m-35 {
        margin: 35px;
    }
    .md-m-40 {
        margin: 40px;
    }
    .md-m-45 {
        margin: 45px;
    }
    .md-m-50 {
        margin: 50px;
    }
    .md-m-55 {
        margin: 55px;
    }
    .md-m-60 {
        margin: 60px;
    }
    .md-m-65 {
        margin: 65px;
    }
    .md-m-70 {
        margin: 70px;
    }
    .md-m-75 {
        margin: 75px;
    }
    .md-m-95 {
        margin: 95px;
    }
    .md-m-100 {
        margin: 100px;
    }
    .md-mt-0 {
        margin-top: 0px;
    }
    .md-mt-5 {
        margin-top: 5px;
    }
    .md-mt-10 {
        margin-top: 10px;
    }
    .md-mt-15 {
        margin-top: 15px;
    }
    .md-mt-20 {
        margin-top: 20px;
    }
    .md-mt-25 {
        margin-top: 25px;
    }
    .md-mt-30 {
        margin-top: 30px;
    }
    .md-mt-35 {
        margin-top: 35px;
    }
    .md-mt-40 {
        margin-top: 40px;
    }
    .md-mt-45 {
        margin-top: 45px;
    }
    .md-mt-50 {
        margin-top: 50px;
    }
    .md-mt-55 {
        margin-top: 55px;
    }
    .md-mt-60 {
        margin-top: 60px;
    }
    .md-mt-65 {
        margin-top: 65px;
    }
    .md-mt-70 {
        margin-top: 70px;
    }
    .md-mt-75 {
        margin-top: 75px;
    }
    .md-mt-95 {
        margin-top: 95px;
    }
    .md-mt-100 {
        margin-top: 100px;
    }
    .md-mr-0 {
        margin-right: 0px;
    }
    .md-mr-5 {
        margin-right: 5px;
    }
    .md-mr-10 {
        margin-right: 10px;
    }
    .md-mr-15 {
        margin-right: 15px;
    }
    .md-mr-20 {
        margin-right: 20px;
    }
    .md-mr-25 {
        margin-right: 25px;
    }
    .md-mr-30 {
        margin-right: 30px;
    }
    .md-mr-35 {
        margin-right: 35px;
    }
    .md-mr-40 {
        margin-right: 40px;
    }
    .md-mr-45 {
        margin-right: 45px;
    }
    .md-mr-50 {
        margin-right: 50px;
    }
    .md-mr-55 {
        margin-right: 55px;
    }
    .md-mr-60 {
        margin-right: 60px;
    }
    .md-mr-65 {
        margin-right: 65px;
    }
    .md-mr-70 {
        margin-right: 70px;
    }
    .md-mr-75 {
        margin-right: 75px;
    }
    .md-mr-95 {
        margin-right: 95px;
    }
    .md-mr-100 {
        margin-right: 100px;
    }
    .md-mb-0 {
        margin-bottom: 0px;
    }
    .md-mb-5 {
        margin-bottom: 5px;
    }
    .md-mb-10 {
        margin-bottom: 10px;
    }
    .md-mb-15 {
        margin-bottom: 15px;
    }
    .md-mb-20 {
        margin-bottom: 20px;
    }
    .md-mb-25 {
        margin-bottom: 25px;
    }
    .md-mb-30 {
        margin-bottom: 30px;
    }
    .md-mb-35 {
        margin-bottom: 35px;
    }
    .md-mb-40 {
        margin-bottom: 40px;
    }
    .md-mb-45 {
        margin-bottom: 45px;
    }
    .md-mb-50 {
        margin-bottom: 50px;
    }
    .md-mb-55 {
        margin-bottom: 55px;
    }
    .md-mb-60 {
        margin-bottom: 60px;
    }
    .md-mb-65 {
        margin-bottom: 65px;
    }
    .md-mb-70 {
        margin-bottom: 70px;
    }
    .md-mb-75 {
        margin-bottom: 75px;
    }
    .md-mb-95 {
        margin-bottom: 95px;
    }
    .md-mb-100 {
        margin-bottom: 100px;
    }
    .md-ml-0 {
        margin-left: 0px;
    }
    .md-ml-5 {
        margin-left: 5px;
    }
    .md-ml-10 {
        margin-left: 10px;
    }
    .md-ml-15 {
        margin-left: 15px;
    }
    .md-ml-20 {
        margin-left: 20px;
    }
    .md-ml-25 {
        margin-left: 25px;
    }
    .md-ml-30 {
        margin-left: 30px;
    }
    .md-ml-35 {
        margin-left: 35px;
    }
    .md-ml-40 {
        margin-left: 40px;
    }
    .md-ml-45 {
        margin-left: 45px;
    }
    .md-ml-50 {
        margin-left: 50px;
    }
    .md-ml-55 {
        margin-left: 55px;
    }
    .md-ml-60 {
        margin-left: 60px;
    }
    .md-ml-65 {
        margin-left: 65px;
    }
    .md-ml-70 {
        margin-left: 70px;
    }
    .md-ml-75 {
        margin-left: 75px;
    }
    .md-ml-95 {
        margin-left: 95px;
    }
    .md-ml-100 {
        margin-left: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .lg-p-0 {
        padding: 0px;
    }
    .lg-p-5 {
        padding: 5px;
    }
    .lg-p-10 {
        padding: 10px;
    }
    .lg-p-15 {
        padding: 15px;
    }
    .lg-p-20 {
        padding: 20px;
    }
    .lg-p-25 {
        padding: 25px;
    }
    .lg-p-30 {
        padding: 30px;
    }
    .lg-p-35 {
        padding: 35px;
    }
    .lg-p-40 {
        padding: 40px;
    }
    .lg-p-45 {
        padding: 45px;
    }
    .lg-p-50 {
        padding: 50px;
    }
    .lg-p-55 {
        padding: 55px;
    }
    .lg-p-60 {
        padding: 60px;
    }
    .lg-p-65 {
        padding: 65px;
    }
    .lg-p-70 {
        padding: 70px;
    }
    .lg-p-75 {
        padding: 75px;
    }
    .lg-p-95 {
        padding: 95px;
    }
    .lg-p-100 {
        padding: 100px;
    }
    .lg-pt-0 {
        padding-top: 0px;
    }
    .lg-pt-5 {
        padding-top: 5px;
    }
    .lg-pt-10 {
        padding-top: 10px;
    }
    .lg-pt-15 {
        padding-top: 15px;
    }
    .lg-pt-20 {
        padding-top: 20px;
    }
    .lg-pt-25 {
        padding-top: 25px;
    }
    .lg-pt-30 {
        padding-top: 30px;
    }
    .lg-pt-35 {
        padding-top: 35px;
    }
    .lg-pt-40 {
        padding-top: 40px;
    }
    .lg-pt-45 {
        padding-top: 45px;
    }
    .lg-pt-50 {
        padding-top: 50px;
    }
    .lg-pt-55 {
        padding-top: 55px;
    }
    .lg-pt-60 {
        padding-top: 60px;
    }
    .lg-pt-65 {
        padding-top: 65px;
    }
    .lg-pt-70 {
        padding-top: 70px;
    }
    .lg-pt-75 {
        padding-top: 75px;
    }
    .lg-pt-95 {
        padding-top: 95px;
    }
    .lg-pt-100 {
        padding-top: 100px;
    }
    .lg-pr-0 {
        padding-right: 0px;
    }
    .lg-pr-5 {
        padding-right: 5px;
    }
    .lg-pr-10 {
        padding-right: 10px;
    }
    .lg-pr-15 {
        padding-right: 15px;
    }
    .lg-pr-20 {
        padding-right: 20px;
    }
    .lg-pr-25 {
        padding-right: 25px;
    }
    .lg-pr-30 {
        padding-right: 30px;
    }
    .lg-pr-35 {
        padding-right: 35px;
    }
    .lg-pr-40 {
        padding-right: 40px;
    }
    .lg-pr-45 {
        padding-right: 45px;
    }
    .lg-pr-50 {
        padding-right: 50px;
    }
    .lg-pr-55 {
        padding-right: 55px;
    }
    .lg-pr-60 {
        padding-right: 60px;
    }
    .lg-pr-65 {
        padding-right: 65px;
    }
    .lg-pr-70 {
        padding-right: 70px;
    }
    .lg-pr-75 {
        padding-right: 75px;
    }
    .lg-pr-95 {
        padding-right: 95px;
    }
    .lg-pr-100 {
        padding-right: 100px;
    }
    .lg-pb-0 {
        padding-bottom: 0px;
    }
    .lg-pb-5 {
        padding-bottom: 5px;
    }
    .lg-pb-10 {
        padding-bottom: 10px;
    }
    .lg-pb-15 {
        padding-bottom: 15px;
    }
    .lg-pb-20 {
        padding-bottom: 20px;
    }
    .lg-pb-25 {
        padding-bottom: 25px;
    }
    .lg-pb-30 {
        padding-bottom: 30px;
    }
    .lg-pb-35 {
        padding-bottom: 35px;
    }
    .lg-pb-40 {
        padding-bottom: 40px;
    }
    .lg-pb-45 {
        padding-bottom: 45px;
    }
    .lg-pb-50 {
        padding-bottom: 50px;
    }
    .lg-pb-55 {
        padding-bottom: 55px;
    }
    .lg-pb-60 {
        padding-bottom: 60px;
    }
    .lg-pb-65 {
        padding-bottom: 65px;
    }
    .lg-pb-70 {
        padding-bottom: 70px;
    }
    .lg-pb-75 {
        padding-bottom: 75px;
    }
    .lg-pb-95 {
        padding-bottom: 95px;
    }
    .lg-pb-100 {
        padding-bottom: 100px;
    }
    .lg-pl-0 {
        padding-left: 0px;
    }
    .lg-pl-5 {
        padding-left: 5px;
    }
    .lg-pl-10 {
        padding-left: 10px;
    }
    .lg-pl-15 {
        padding-left: 15px;
    }
    .lg-pl-20 {
        padding-left: 20px;
    }
    .lg-pl-25 {
        padding-left: 25px;
    }
    .lg-pl-30 {
        padding-left: 30px;
    }
    .lg-pl-35 {
        padding-left: 35px;
    }
    .lg-pl-40 {
        padding-left: 40px;
    }
    .lg-pl-45 {
        padding-left: 45px;
    }
    .lg-pl-50 {
        padding-left: 50px;
    }
    .lg-pl-55 {
        padding-left: 55px;
    }
    .lg-pl-60 {
        padding-left: 60px;
    }
    .lg-pl-65 {
        padding-left: 65px;
    }
    .lg-pl-70 {
        padding-left: 70px;
    }
    .lg-pl-75 {
        padding-left: 75px;
    }
    .lg-pl-95 {
        padding-left: 95px;
    }
    .lg-pl-100 {
        padding-left: 100px;
    }
    .lg-m-0 {
        margin: 0px;
    }
    .lg-m-5 {
        margin: 5px;
    }
    .lg-m-10 {
        margin: 10px;
    }
    .lg-m-15 {
        margin: 15px;
    }
    .lg-m-20 {
        margin: 20px;
    }
    .lg-m-25 {
        margin: 25px;
    }
    .lg-m-30 {
        margin: 30px;
    }
    .lg-m-35 {
        margin: 35px;
    }
    .lg-m-40 {
        margin: 40px;
    }
    .lg-m-45 {
        margin: 45px;
    }
    .lg-m-50 {
        margin: 50px;
    }
    .lg-m-55 {
        margin: 55px;
    }
    .lg-m-60 {
        margin: 60px;
    }
    .lg-m-65 {
        margin: 65px;
    }
    .lg-m-70 {
        margin: 70px;
    }
    .lg-m-75 {
        margin: 75px;
    }
    .lg-m-95 {
        margin: 95px;
    }
    .lg-m-100 {
        margin: 100px;
    }
    .lg-mt-0 {
        margin-top: 0px;
    }
    .lg-mt-5 {
        margin-top: 5px;
    }
    .lg-mt-10 {
        margin-top: 10px;
    }
    .lg-mt-15 {
        margin-top: 15px;
    }
    .lg-mt-20 {
        margin-top: 20px;
    }
    .lg-mt-25 {
        margin-top: 25px;
    }
    .lg-mt-30 {
        margin-top: 30px;
    }
    .lg-mt-35 {
        margin-top: 35px;
    }
    .lg-mt-40 {
        margin-top: 40px;
    }
    .lg-mt-45 {
        margin-top: 45px;
    }
    .lg-mt-50 {
        margin-top: 50px;
    }
    .lg-mt-55 {
        margin-top: 55px;
    }
    .lg-mt-60 {
        margin-top: 60px;
    }
    .lg-mt-65 {
        margin-top: 65px;
    }
    .lg-mt-70 {
        margin-top: 70px;
    }
    .lg-mt-75 {
        margin-top: 75px;
    }
    .lg-mt-95 {
        margin-top: 95px;
    }
    .lg-mt-100 {
        margin-top: 100px;
    }
    .lg-mr-0 {
        margin-right: 0px;
    }
    .lg-mr-5 {
        margin-right: 5px;
    }
    .lg-mr-10 {
        margin-right: 10px;
    }
    .lg-mr-15 {
        margin-right: 15px;
    }
    .lg-mr-20 {
        margin-right: 20px;
    }
    .lg-mr-25 {
        margin-right: 25px;
    }
    .lg-mr-30 {
        margin-right: 30px;
    }
    .lg-mr-35 {
        margin-right: 35px;
    }
    .lg-mr-40 {
        margin-right: 40px;
    }
    .lg-mr-45 {
        margin-right: 45px;
    }
    .lg-mr-50 {
        margin-right: 50px;
    }
    .lg-mr-55 {
        margin-right: 55px;
    }
    .lg-mr-60 {
        margin-right: 60px;
    }
    .lg-mr-65 {
        margin-right: 65px;
    }
    .lg-mr-70 {
        margin-right: 70px;
    }
    .lg-mr-75 {
        margin-right: 75px;
    }
    .lg-mr-95 {
        margin-right: 95px;
    }
    .lg-mr-100 {
        margin-right: 100px;
    }
    .lg-mb-0 {
        margin-bottom: 0px;
    }
    .lg-mb-5 {
        margin-bottom: 5px;
    }
    .lg-mb-10 {
        margin-bottom: 10px;
    }
    .lg-mb-15 {
        margin-bottom: 15px;
    }
    .lg-mb-20 {
        margin-bottom: 20px;
    }
    .lg-mb-25 {
        margin-bottom: 25px;
    }
    .lg-mb-30 {
        margin-bottom: 30px;
    }
    .lg-mb-35 {
        margin-bottom: 35px;
    }
    .lg-mb-40 {
        margin-bottom: 40px;
    }
    .lg-mb-45 {
        margin-bottom: 45px;
    }
    .lg-mb-50 {
        margin-bottom: 50px;
    }
    .lg-mb-55 {
        margin-bottom: 55px;
    }
    .lg-mb-60 {
        margin-bottom: 60px;
    }
    .lg-mb-65 {
        margin-bottom: 65px;
    }
    .lg-mb-70 {
        margin-bottom: 70px;
    }
    .lg-mb-75 {
        margin-bottom: 75px;
    }
    .lg-mb-95 {
        margin-bottom: 95px;
    }
    .lg-mb-100 {
        margin-bottom: 100px;
    }
    .lg-ml-0 {
        margin-left: 0px;
    }
    .lg-ml-5 {
        margin-left: 5px;
    }
    .lg-ml-10 {
        margin-left: 10px;
    }
    .lg-ml-15 {
        margin-left: 15px;
    }
    .lg-ml-20 {
        margin-left: 20px;
    }
    .lg-ml-25 {
        margin-left: 25px;
    }
    .lg-ml-30 {
        margin-left: 30px;
    }
    .lg-ml-35 {
        margin-left: 35px;
    }
    .lg-ml-40 {
        margin-left: 40px;
    }
    .lg-ml-45 {
        margin-left: 45px;
    }
    .lg-ml-50 {
        margin-left: 50px;
    }
    .lg-ml-55 {
        margin-left: 55px;
    }
    .lg-ml-60 {
        margin-left: 60px;
    }
    .lg-ml-65 {
        margin-left: 65px;
    }
    .lg-ml-70 {
        margin-left: 70px;
    }
    .lg-ml-75 {
        margin-left: 75px;
    }
    .lg-ml-95 {
        margin-left: 95px;
    }
    .lg-ml-100 {
        margin-left: 100px;
    }
}
